.match {
    background-color: lightgreen;
}

.mismatch {
    background-color: lightcoral;
}





