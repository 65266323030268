.myEvent:hover {
  cursor: pointer;
}

.myHeader a {
  color: var(--text-primary);
  text-decoration: none;
}

.myDay a {
  text-decoration: none;
  color: var(--text-primary);
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(80, 211, 10, 0.15);
  /* background-color: var(--fc-today-bg-color, rgba(255, 220, 40, 0.15)); */
}

.fc .fc-daygrid-event {
  padding: 2px;
  margin-bottom: 5px;
  background-color: #6e6ef2;
  color: white;
  border: none;
}

.fc .fc-button-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--fc-button-text-color);
}
.fc .fc-button-primary:disabled {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--fc-button-text-color);
}
.fc .fc-button-primary:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: var(--fc-button-text-color);
}
.fc:focus .fc-button-primary:focus {
  background-color: var(--primary-hover);
  box-shadow: 0;
  border-color: 0;
  outline: 0;
}
.fc:active .fc-button-primary:active {
  background-color: var(--primary-hover);
  box-shadow: none;
  border: none;
  outline: 0;
}
