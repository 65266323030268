.form-control {
    padding: 2px;
    padding-left: 5px;
    font-size: 14px;
}

p {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.match {
    background-color: lightgreen;
}

.mismatch {
    background-color: lightcoral;
}

.timesheetRowHeader {
    border: 1px solid #ccc;
    background: #eee;
}

@media print {
    p {
        font-size: 12px;
    }

    h4 {
        font-size: 1.2rem;
    }

    .entry, .row {
        font-size: 12px;
    }

    .timesheetRowHeader {
        border: 1px solid #ccc;
        background: #eee;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .match {
        background-color: lightgreen;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .mismatch {
        background-color: lightcoral;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .pagebreak {
        break-after: always !important;
        page-break-after: always !important;
    }
}