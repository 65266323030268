@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* COLOR SCHEMES */
:root {
  --background: #ffffff;

  --primary: #7fa344;
  --text-primary: rgb(0, 0, 0);
  --text-light: rgb(255, 255, 255);
  --primary-hover: #596c31;

  --secondary: #6757a8;
  --text-secondary: royalblue;
  --secondary-hover: #4d34ae;

  --danger: #b85252;
  --text-danger: royalblue;
  --danger-hover: #7e2020f1;

  --success: #20953d;
  --text-success: royalblue;
  --success-hover: #196e2e;

  --text-light: white;
  --accent: rgba(128, 0, 128, 0.182);

  --con: #eeeeff;

  --list-item: #ffffff;
  --list-item-hover: #e0e0e0;

  --inner-con-hover: #f7f7f7;

  --navbar: #515199;
  --link: #0066fe;

  --incoming: #d7ccff;
  --outgoing: #b6d880;
}

[data-theme="dark"] {
  --background: rgba(0, 0, 0, 0.897);

  --primary: rgba(47, 47, 47, 0.675);
  --primary-hover: #5f5f5f;

  --secondary: rgba(84, 84, 84, 0.675);
  --secondary-hover: #5f5f5f;

  --text-primary: white;
  --text-secondary: grey;

  --accent: darkred;

  --con: rgb(66, 66, 66);

  --list-item: #585858;
  --list-item-hover: #727272f8;

  --navbar: #212529;
  --link: #7398ff;

  --incoming: #585858;
  --outgoing: #585858;
}

.app {
  min-height: 100vh;
  color: var(--text-primary);
  background-color: var(--background);
  transition: all 0.5s;
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hoverMe {
  cursor: pointer;
}

.row {
  padding: 0;
  margin: 0;
}

.Toastify__toast-container--top-right {
  top: 70px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.form-floating label {
  color: black;
}

.innerCon {
  background-color: var(--list-item);
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
/* .innerCon:hover {
  background-color: var(--inner-con-hover);
} */

.listItem:hover {
  background-color: var(--list-item-hover);
}

/* CONTAINERS*/
.primaryShadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background-color: var(--con);
  border-radius: 3px;
  transition: all 0.1s;
}

.sidebarItem:hover {
  background-color: var(--list-item-hover);
}

a {
  color: var(--link);
  text-decoration: underline;
}

.btn-primary.active:focus,
.btn-primary:active:focus {
  box-shadow: none;
}

/* NAVBAR */

.navabr-toggler {
  color: white;
}

/* Buttons */
.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
}
.btn-primary:hover {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}

.btn-primary:focus {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.25rem rgb(var(--primary));
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn-primary.active,
.btn-primary:active {
  color: #fff;
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn-danger {
  background: var(--danger);
  border-color: var(--danger);
}
.btn-danger:hover {
  background: var(--danger-hover);
  border-color: var(--danger-hover);
}

.btn-secondary {
  background: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary:hover {
  background: var(--secondary-hover);
  border-color: var(--secondary-hover);
}

.btn-success {
  background: var(--success);
  border-color: var(--success);
}
.btn-success:hover {
  background: var(--success-hover);
  border-color: var(--success-hover);
}



@media print {
    p {
        font-size: 20px;
    }

    .no-print {
        display: none;
    }

    .pagebreak {
        break-after: always !important;
        page-break-after: always !important;
    }

    .noBreak {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
    }
}