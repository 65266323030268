.enquiryStatus .recievedTotal {
  /* border-radius: 50%; */
  background: var(--success);
  color: white;
}
.enquiryStatus .openTotal {
  /* border-radius: 50%; */
  background: var(--danger);
  color: white;
}
