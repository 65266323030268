.topNav {
  background: var(--navbar);
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--text-light);
}

.topNav .nav-link,
.topNav a {
  color: var(--text-light);
}

.topNav .nav-link:hover,
.topNav a:hover {
  color: #ccc;
}

.topNav .nav-link:focus,
.topNav a:focus {
  color: #ccc;
}
.topNav .nav-link::after,
.topNav a::after {
  color: #ccc;
}

.topNav .active {
  color: #ccc;
}

.topNav a::after {
  color: #ccc;
}
