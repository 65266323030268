.app {
  min-height: 100vh;
  color: var(--text-primary);
  background-color: var(--background);
  transition: all 0.5s;
  font-size: 16px;
  margin: 0;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-link {
  color: #61dafb;
}

.loginForm {
  background: #fff;
  border-radius: 6px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
.form-floating label {
  color: black;
}

.body-scroll {
  overflow-y: scroll;
}

.body-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  margin-right: 2px;
}

.body-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #333;
  border-radius: 10px;
}

.body-scroll::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  opacity: 0.6;
  border-radius: 10px;
}

.body-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

.stack-top {
  z-index: 10;
}

.searchDropDownCon {
  position: absolute;
  top: 100;
  left: 0;
  z-index: 999;
  background: white;
  width: 100%;
}
