@media only screen and (min-width: 1300px) {
  .usersListCon {
    position: fixed;
    height: 95%;
    width: 20vw;
    transition: left 0.3s ease-in-out;
    /* background-color: var(--sidebar); */
    overflow: auto;
  }
}
