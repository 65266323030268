.popupContainer {
    position:       absolute;
    left:           0px;
    top:            0px;
    width:          100%;
    height:         100%;
    background:     gray;
    filter:         alpha(Opacity = 50);
    opacity:        0.5;
}


.alert {
    opacity:        1;
    background-color: #fff;
    position:absolute;
    top:50%;
    left:50%;
    width:450px;  /* adjust as per your needs */
    height:200px;   /* adjust as per your needs */
    margin-left:-200px;   /* negative half of width above */
    margin-top:-200px;   /* negative half of height above */
    border: 1px solid #000;
    
}

.alert button {
    margin-right: 20px;
    margin-top: 20px;
}