.enquiryDetails a {
  color: var(--link);
}

.my-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

.my-grid-container > div {
  height: auto;
}