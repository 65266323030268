.sideCon {
  background: var(--side-con);
}

@media only screen and (min-width: 1200px) {
  .sideCon {
    position: fixed;
    height: 95%;
    width: 23vw;
    transition: left 0.3s ease-in-out;
    /* background-color: var(--sidebar); */
    overflow: auto;
    border-right: 1px solid var(--con);
  }
}
