 .primary {
    /* border-radius: 50%; */
    background: var(--primary);
    color: white;
}

.success {
    /* border-radius: 50%; */
    background: var(--success);
    color: white;
}
.fail {
    /* border-radius: 50%; */
    background: var(--danger);
    color: white;
}

.rowHeader {
    border: 1px solid #ccc;
    background: #eee;
}
